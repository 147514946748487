import { Avatar, Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";

const Card = ({ name, image, id, data }) => {
  const location = useHistory();
  const nextRoute = () => {
    location.push({ pathname: `/admin/manage-wocman/${id}`, state: data });
  };
  return (
    <Box cursor={"pointer"} onClick={nextRoute} w="182px">
      <Flex mb="-3.5rem" justify={"center"}>
        <Box w="74px" h="74px" borderRadius={"50%"}>
          {image ? (
            <Image
              w="74px"
              h="74px"
              borderRadius={"50%"}
              src={image}
              alt="profile_image"
            />
          ) : (
            <Avatar
              bgColor="rgba(2, 4, 7, 0.2)"
              color="#3E7EEE"
              name={name ? name : "Wocman user"}
              w="74px"
              h="74px"
            />
          )}
        </Box>
      </Flex>

      <Box
        w="182px"
        height={"105px"}
        bg="#F0EFEF"
        borderRadius={"8px"}
        boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.11)"}
      >
        <Flex justify={"center"} pt="4rem">
          <Box>
            <Text fontWeight={"900"} fontFamily={"bold"}>
              Wocman name
            </Text>
            <Text
              textAlign={"center"}
              textTransform={"capitalize"}
              fontFamily={"bold"}
            >
              {name ? name : "Wocman user"}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Card;
