import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import theme from "./theme";
import { Login } from "./pages";
import { AdminRoutes } from "./routes/Routes";
import ContextProviders from "./context/ContextProviders";

function App() {
  const queryClient = new QueryClient();

  return (
    <ContextProviders>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Switch>
              <Route exact path="/">
                <Login />
              </Route>
              <AdminRoutes />
              <Route exact path="*" component={Login} />
            </Switch>
          </Router>
        </QueryClientProvider>
      </ChakraProvider>
    </ContextProviders>
  );
}

export { App };
