import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

const SidebarItem = ({ icon, text }) => {
  return (
    <Flex align="center">
      <Box boxSize="12px">{icon}</Box>
      <Text ml="24px" lineHeight="16px" fontSize="15px">
        {text}
      </Text>
    </Flex>
  );
};

export default SidebarItem;
