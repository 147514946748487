import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  lg: "1200px",
  xl: "1441px",
});

const colors = {
  brand: {
    brown: "#552D1E",
    black: "#333758",
    landing: "#047EFF",
  },
};

const fontFamily = {
  fonts: {
    Xlight: '"TT Norms XLight", sans-serif',
    light: '"TT Norms Light", sans-serif',
    thin: '"TT Norms Thin", sans-serif',
    regular: '"TT Norms Regular", sans-serif',
    medium: '"TT Norms Medium", sans-serif',
    bold: '"TT Norms Bold", sans-serif',
    XBold: '"TT Norms XBold", sans-serif',
    black: '"TT Norms Black", sans-serif',
  },
};

const styles = {
  global: {
    html: {
      fontSize: "62.5%",
      boxSizing: "border-box",
    },
    body: {
      fontSize: "1.4rem",
      fontFamily: "'Karla', sans-serif",
      color: "#333758",
    },
    a: {
      color: "#333758",
    },
    "::placeholder": {
      color: "rgba(51, 55, 88, 0.3)",
    },
  },
};

const customTheme = extendTheme({ styles, colors, breakpoints, fontFamily });

export default customTheme;
