import instance from "./axios.config";

const getRecentProjects = async (page) => {
  const { data } = await instance.get(
    `/admin/dashboard/projects?limit=6&page=${page}&status=pending`
  );

  return data;
};

const getSingleProject = async (id) => {
  const { data } = await instance.get(`/admin/dashboard/projects/${id}`);

  return data;
};

const setProjectAmount = async (id, body) => {
  const { data } = await instance.put(
    `/admin/dashboard/projects/amount/${id}`,
    body
  );

  return data;
};

const approveProject = async (id) => {
  const { data } = await instance.put(
    `/admin/dashboard/projects/approve/${id}?status=approved`
  );

  return data;
};


export {
  getRecentProjects,
  getSingleProject,
  setProjectAmount,
  approveProject,
};
