import { Box, Flex } from "@chakra-ui/react";
import { WorkDescription } from "./WorkDescription";

const ViewProject = () => {
  return (
    <Box w="100%">
      <Flex
        // justify="center"
        align="center"
        w="100%"
        h="100%"
        // flex={1}
        flexDir="column"
        p={{ base: 4, md: 8 }}
      >
        <WorkDescription />
      </Flex>
    </Box>
  );
};

export { ViewProject };
