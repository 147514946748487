import instance from "./axios.config";

const getDashboard = async (page) => {
  const { data } = await instance.get(
    `/admin/dashboard/projects?limit=6&page=${page}`
  );

  return data;
};

const getDashboardMetrics = async () => {
  const { data } = await instance.get(`/admin/dashboard/projects/metrics`);

  return data;
};

const getAllProjectsFiltered = async (page, status) => {
  const { data } = await instance.get(
    `/admin/dashboard/projects?limit=6&page=${page}&status=${status}`
  );

  return data;
};

const getProjectView = async (id) => {
  const { data } = await instance.get(`/admin/dashboard/projects/${id}`);

  return data;
};

export {
  getDashboard,
  getDashboardMetrics,
  getAllProjectsFiltered,
  getProjectView,
};
