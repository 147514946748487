import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Marquee from "react-fast-marquee";
import AOS from "aos";

import "aos/dist/aos.css";
import { Card } from "./Card";
import Labels from "./Labels";
import { statusCheck } from "../../utils";
import { getDashboard, getDashboardMetrics } from "../../queries";
import { Pagination, Spinner } from "../../components";

export const Dashboard = () => {
  const [projects, setProjects] = useState([]);
  const [page, setPage] = useState(1);

  const { isLoading, isError, data } = useQuery(["getDashboard", page], () =>
    getDashboard(page)
  );
  const {
    isLoading: metricsLoading,
    isError: metricsError,
    data: metrics,
  } = useQuery("getDashboardMetrics", getDashboardMetrics);

  useEffect(() => {
    setProjects(data?.data?.rows);
  }, [data]);

  useEffect(() => {
    AOS.init();
  }, [data]);

  const shldPaginate = data?.data?.total > 6;
  const pageComplete = projects?.length < 6;

  const prevPage = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const nextPage = () => {
    if (pageComplete) return;
    setPage(page + 1);
  };

  return (
    <>
      {metricsLoading || metricsError ? (
        <Flex align={"center"} justify="center" w="100%" h={"10vh"}>
          <Spinner />
        </Flex>
      ) : (
        <Marquee gradient={false}>
          <Flex gap="20px" width={"100%"}>
            <Labels
              value={metrics?.data?.recent_job}
              name="Recent Jobs"
              background="#F96662"
            />
            <Labels
              value={metrics?.data?.pending_job}
              name="Jobs waiting for approval"
              background="#ED8B30"
            />
            <Labels
              value={metrics?.data?.approved_job}
              name="Jobs in progress"
              background="#34B439"
            />
            <Labels
              value={metrics?.data?.completed_job}
              name="Completed Jobs"
              background=" #293DF1"
              mr={{ base: "1.5rem", lg: "0rem" }}
            />
          </Flex>
        </Marquee>
      )}
      {isLoading || isError ? (
        <Flex align={"center"} justify="center" w="100%" h={"50vh"}>
          <Spinner />
        </Flex>
      ) : (
        <Flex mt={"2rem"} gap="30px" flexWrap="wrap">
          {projects?.map((d) => (
            <Card
              key={d.id}
              title={d.project}
              description={d.description}
              body={d.body}
              status={d.status}
              color={statusCheck(d.status)}
            />
          ))}
        </Flex>
      )}
      <Pagination
        shldPaginate={shldPaginate}
        page={page}
        prevPage={prevPage}
        nextPage={nextPage}
      />
    </>
  );
};
