import React from "react";
import { Redirect, Route } from "react-router-dom";

function PrivateRoute({ path, exact, component }) {
  const token = localStorage.getItem("admin_token");
  // Check is user is logged in
  if (!token) {
    return <Redirect to="/" />;
  }

  return <Route path={path} exact={exact} component={component} />;
}

export { PrivateRoute };
