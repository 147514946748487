/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import { SidebarData } from "../../data";
import SidebarItem from "./SidebarItem";
import logo from "../../assets/images/logo.png";
import { useMenu } from "../../context/MenuContext";

const Sidebar = () => {
  const { isMenuOpen, setIsMenuOpen } = useMenu();

  const handleSetMobileMenu = () => {
    if (window.screen.width < 768) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    handleSetMobileMenu();
  }, []);

  const logOut = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  return (
    <>
      {isMenuOpen && (
        <Box
          data-testid="menu-data"
          pos="fixed"
          boxShadow="xl"
          left="0"
          top={{ base: "10%", lg: "0" }}
          zIndex={{ base: "100" }}
        >
          <Box
            bgColor="#fff"
            w={{ base: "100vw", md: "246px" }}
            h={{ base: "100vh", md: "100vh" }}
            color="#242424"
            p="30px 32px"
          >
            <Box pl="25px" pt="21px" mb="50px">
              <Image src={logo} alt="logo" h="87px" w="64px" />
            </Box>
            {SidebarData.map((el, id) => (
              <Flex
                align="center"
                pl="21px"
                key={id}
                h="34px"
                mb="3rem"
                transition=".7s"
                as={NavLink}
                to={el.path}
                onClick={handleSetMobileMenu}
                exact
                activeClassName="activeRoute"
              >
                <SidebarItem icon={el.icon} text={el.name} path={el.path} />
              </Flex>
            ))}
            <Flex
              mt={{ base: "0rem", lg: "10rem" }}
              align="center"
              justify="center"
              bg="brand.brown"
              color="#fff"
              w="198px"
              h="28px"
              cursor="pointer"
              borderRadius={"10px"}
              onClick={logOut}
            >
              Logout out
            </Flex>
          </Box>
        </Box>
      )}
    </>
  );
};

export { Sidebar };
