import {
  DashboardIcon,
  RecentIcon,
  ManageIcon,
  ProjectIcon,
} from "../components/Svgs";

export const SidebarData = [
  {
    icon: DashboardIcon,
    name: "Dashboard",
    path: "/admin",
  },
  {
    icon: RecentIcon,
    name: "Recent Projects",
    path: "/admin/recent-projects",
  },
  {
    icon: ProjectIcon,
    name: "Projects",
    path: "/admin/projects",
  },
  {
    icon: ManageIcon,
    name: "Manage Wocmen",
    path: "/admin/manage-wocmen",
  },
];
