import { Flex, Input, Text } from "@chakra-ui/react";
import React from "react";

export const BaseInput = ({
  label,
  placeholder,
  id,
  type,
  readonly,
  width,
  right,
  ...rest
}) => {
  return (
    <Flex direction={{ base: "column", lg: "row" }} align={"center"}>
      <Input
        focusBorderColor="brand.brown"
        fontSize="1.3rem"
        type={type}
        bg="#F7F9FB"
        border="1px solid #552D1E"
        borderRadius="3px"
        h="4.2rem"
        w={width || "27rem"}
        px="1.6rem"
        placeholder={placeholder}
        readOnly={readonly}
        {...rest}
      />
      <Text
        ml="10px"
        fontFamily={"bold"}
        fontSize="20px"
        as="label"
        htmlFor={id}
      >
        {label}
      </Text>
    </Flex>
  );
};
