import React from "react";
import { CenterModal } from "../../components";
import { Image } from "@chakra-ui/react";

const CertificateModal = ({ isCert, setIsCert, cert }) => {
  return (
    <CenterModal width="700px" showModal={isCert} setShowModal={setIsCert}>
      <Image src={cert} w="100%" height="30%" alt="certificate" />
    </CenterModal>
  );
};

export default CertificateModal;
