/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

import active from "../../assets/icons/Vector.png";
import suspend from "../../assets/icons/suspend.png";
import activate from "../../assets/icons/activate.png";
import ban from "../../assets/icons/Union.png";
import { useParams, useLocation } from "react-router-dom";
import { CenterModal } from "../../components";
import instance from "../../queries/axios.config";
import CertificateModal from "./CertificateModal";

export const SingleWocman = () => {
  const { id } = useParams();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSuspend, setIsOpenSuspend] = useState(false);
  const [wocman, setWocman] = useState("");
  const [isCert, setIsCert] = useState(false);

  useEffect(() => {
    setWocman(location?.state);
  }, [location]);

  const handleActive = async () => {
    try {
      await instance.put(`/admin/wocman/change-status/${id}?status=active`);
      setIsOpen(true);
    } catch (error) {
      throw error;
    }
  };

  const handleSuspend = async () => {
    try {
      await instance.put(`/admin/wocman/change-status/${id}?status=suspend`);
      setIsOpenSuspend(true);
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <Box h="70vh" w="100%" bg="#fff">
        <Flex bg="brand.brown" h={"4rem"} justify="center" align={"center"}>
          <Text fontSize={"16px"} fontFamily={"bold"} color={"#fff"}>
            Take action
          </Text>
        </Flex>
        <Flex
          padding={{ base: "2rem", lg: "0rem" }}
          mt="2rem"
          width={"100%"}
          justify={"center"}
        >
          <Box width={{ base: "100%", lg: "40%" }}>
            <Image
              borderRadius={"10px"}
              mb="2rem"
              w="100px"
              height={"100px"}
              src={wocman?.image}
            />
            <Text fontWeight={"900"}>
              Name:
              <Text
                fontWeight={"500"}
                as={"span"}
                textTransform={"capitalize"}
              >{`${wocman?.firstname} ${wocman?.lastname}`}</Text>
            </Text>
            <Text fontWeight={"900"}>
              Skill:
              <Text
                fontWeight={"500"}
                as={"span"}
                textTransform={"capitalize"}
              >{`${wocman?.skill}`}</Text>
            </Text>
            <Text fontWeight={"900"}>
              Competency:
              <Text
                fontWeight={"500"}
                as={"span"}
                textTransform={"capitalize"}
              >{`${wocman?.competency}`}</Text>
            </Text>
            <Text fontWeight={"900"}>
              Address:
              <Text
                fontWeight={"500"}
                as={"span"}
                textTransform={"capitalize"}
              >{`${wocman?.address}`}</Text>
            </Text>
            <Text fontWeight={"900"}>
              Phone:
              <Text
                fontWeight={"500"}
                as={"span"}
                textTransform={"capitalize"}
              >{`${wocman?.phone}`}</Text>
            </Text>
            <Text fontWeight={"900"}>
              Email:
              <Text
                fontWeight={"500"}
                as={"span"}
                textTransform={"capitalize"}
              >{`${wocman?.email}`}</Text>
            </Text>
            <Button
              onClick={() => setIsCert(true)}
              bg="brand.brown"
              color="#fff"
              mt="10px"
              _hover={{ opacity: "0.8" }}
            >
              View Certificate
            </Button>
          </Box>
        </Flex>
        <Flex gap={"20px"} h="30vh" align={"center"} justify="center">
          <Box
            onClick={handleActive}
            cursor={"pointer"}
            h={"141px"}
            w={"224px"}
            bg="#4CAF50"
          >
            <Flex mt="2rem" justify={"center"}>
              <Image h="40px" w="40px" src={active} alt="active-icon" />
            </Flex>
            <Text
              mt="1rem"
              fontFamily={"bold"}
              color={"#fff"}
              textAlign={"center"}
            >
              Reactivate wocman
            </Text>
          </Box>
          <Box
            onClick={handleSuspend}
            cursor={"pointer"}
            h={"141px"}
            w={"224px"}
            bg="#F96662"
          >
            <Flex mt="2rem" justify={"center"}>
              <Image h="40px" w="40px" src={ban} alt="ban-icon" />
            </Flex>
            <Text
              mt="1rem"
              fontFamily={"bold"}
              color={"#fff"}
              textAlign={"center"}
            >
              Suspend wocman
            </Text>
          </Box>
        </Flex>
      </Box>
      <CenterModal showModal={isOpen} setShowModal={setIsOpen}>
        <Flex justify={"center"}>
          <Image src={activate} />
        </Flex>
        <Text
          textAlign={"center"}
          mt="1.5rem"
          mb="5rem"
          fontSize={"20px"}
          fontFamily="bold"
          fontWeight={"600"}
        >
          Wocman reactivated successfully
        </Text>
      </CenterModal>
      <CenterModal showModal={isOpenSuspend} setShowModal={setIsOpenSuspend}>
        <Flex justify={"center"}>
          <Image src={suspend} />
        </Flex>
        <Text
          textAlign={"center"}
          mt="1.5rem"
          mb="5rem"
          fontSize={"20px"}
          fontFamily="bold"
          fontWeight={"600"}
        >
          Wocman suspended successfully
        </Text>
      </CenterModal>
      <CertificateModal
        cert={wocman?.cert_url}
        isCert={isCert}
        setIsCert={setIsCert}
      />
    </>
  );
};
