import axios from "axios";

const apiToken = localStorage.getItem("admin_token");

const instance = axios.create({
  baseURL: "https://wocman-api.onrender.com/api/v1",
});

instance.defaults.headers.common["Authorization"] = `Bearer ${apiToken}`;

export default instance;
