import {
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Box,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";

import { Card } from "../dashboard";
import { statusCheck } from "../../utils";
import { getAllProjectsFiltered } from "../../queries";
import { Pagination, Spinner } from "../../components";
export const Projects = () => {
  const [recentProjects, setRecentProjects] = useState([]);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("pending");

  const { isLoading, isError, data } = useQuery(
    ["getAllProjectsFiltered", page, status],
    () => getAllProjectsFiltered(page, status)
  );

  useEffect(() => {
    setRecentProjects(data?.data?.rows);
  }, [data]);

  const shldPaginate = data?.data?.total > 6;
  const pageComplete = recentProjects?.length < 6;

  const prevPage = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const nextPage = () => {
    if (pageComplete) return;
    setPage(page + 1);
  };

  if (isLoading || isError) {
    return (
      <Flex align={"center"} justify="center" w="100%" h="80vh">
        <Spinner />
      </Flex>
    );
  }

  return (
    <>
      <Flex
        mb={{ base: "2rem", lg: "0rem" }}
        direction={{ base: "column", lg: "row" }}
        justify={"space-between"}
      >
        <Heading mb="5rem">Recent Projects</Heading>
        <Flex>
          <Text mt="4px" mr="5px" fontWeight={"bold"}>
            Filter By:
          </Text>
          <Menu>
            <MenuButton
              w={"100px"}
              h="3rem"
              fontSize={"14px"}
              background={"brand.brown"}
              className="btn"
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign={"center"}
              color="#fff"
              as={Box}
              cursor="pointer"
              _focus={{ borderColor: "none", bg: "brand.brown" }}
            >
              {status === "" ? "Filter By" : status}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={(e) => setStatus(e.target.textContent)}>
                pending
              </MenuItem>
              <MenuItem onClick={(e) => setStatus(e.target.textContent)}>
                in-progress
              </MenuItem>
              <MenuItem onClick={(e) => setStatus(e.target.textContent)}>
                completed
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <Flex gap="40px" flexWrap="wrap">
        {recentProjects?.map((d) => (
          <Card
            id={d.id}
            key={d.id}
            view
            title={d.project}
            description={d.description}
            body={d.body}
            status={d.status}
            color={statusCheck(d.status)}
          />
        ))}
      </Flex>
      <Pagination
        page={page}
        shldPaginate={shldPaginate}
        prevPage={prevPage}
        nextPage={nextPage}
      />
    </>
  );
};
