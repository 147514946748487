import { Flex, Heading } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";

import { Card } from "../dashboard";
import { statusCheck } from "../../utils";
import { getRecentProjects } from "../../queries";
import { Pagination, Spinner } from "../../components";

const RecentProjects = () => {
  const [recentProjects, setRecentProjects] = useState([]);
  const [page, setPage] = useState(1);

  const { isLoading, isError, data } = useQuery(
    ["getRecentProjects", page],
    () => getRecentProjects(page)
  );

  useEffect(() => {
    setRecentProjects(data?.data?.rows);
  }, [data]);

  const shldPaginate = data?.data?.total > 6;
  const pageComplete = recentProjects?.length < 6;

  const prevPage = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const nextPage = () => {
    if (pageComplete) return;
    setPage(page + 1);
  };

  if (isLoading || isError) {
    return (
      <Flex align={"center"} justify="center" w="100%" h="80vh">
        <Spinner />
      </Flex>
    );
  }
  return (
    <>
      <Heading mb="5rem">Recent Projects</Heading>

      <Flex gap="40px" flexWrap="wrap">
        {recentProjects?.map((d) => (
          <Card
            id={d.id}
            key={d.id}
            title={d.project}
            description={d.description}
            body={d.body}
            status={d.status}
            color={statusCheck(d.status)}
          />
        ))}
      </Flex>
      <Pagination
        page={page}
        shldPaginate={shldPaginate}
        prevPage={prevPage}
        nextPage={nextPage}
      />
    </>
  );
};

export { RecentProjects };
