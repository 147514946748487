import { Box, Text } from "@chakra-ui/react";
import React from "react";

const Labels = ({ value, name, background, ...rest }) => {
  return (
    <>
      <Box
        textAlign="center"
        borderRadius="5px"
        w={{ base: "100%", lg: "20%" }}
        height="136px"
        bg={background}
        {...rest}
      >
        <Text mt="10px" color="#fff" fontSize="48px" fontWeight="bold">
          {value}
        </Text>
        <Text color="#fff" mt="6px">
          {name}
        </Text>
      </Box>
    </>
  );
};

export default Labels;
