import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";

import { getAllWocmanSearch } from "../../queries";
import { BaseInput, Pagination, Spinner } from "../../components";
import Card from "./Card";

export const ManageWocman = () => {
  const [wocmen, setWocmen] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [hasNextPage, setHasNextPage] = useState(false);

  const { isLoading, isError, data } = useQuery(
    ["getAllWocmanSearch", page, search],
    () => getAllWocmanSearch(page, search)
  );

  useEffect(() => {
    setWocmen(data?.data?.data?.rows);
    setHasNextPage(data?.data?.data?.hasNextPage);
  }, [data]);

  const shldPaginate = data?.data?.data?.total > 6;

  const prevPage = () => {
    if (page === 1) return;
    setPage(page - 1);
  };

  const nextPage = () => {
    if (!hasNextPage) return;
    setPage(page + 1);
  };

  return (
    <>
      <Box bg="#fff">
        <Flex bg="brand.brown" h={"4rem"} justify="center" align={"center"}>
          <Text fontSize={"16px"} fontFamily={"bold"} color={"#fff"}>
            Manage Wocman
          </Text>
        </Flex>
        <Box mt="2rem" pr="40px" pl={{ base: "40px", lg: "200px" }}>
          <BaseInput
            label={"Search wocmen"}
            value={search}
            type="text"
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
        {isLoading || isError ? (
          <Flex align={"center"} justify="center" w="100%" h="60vh">
            <Spinner />
          </Flex>
        ) : (
          <Box pb="10rem" pr="40px" pl="30px">
            <Flex
              mb="10rem"
              justify={"center"}
              mt="5rem"
              flexWrap={"wrap"}
              gap="20px 20%"
            >
              {wocmen?.map((wocman) => (
                <Card
                  key={wocman.id}
                  id={wocman.id}
                  data={wocman}
                  name={
                    wocman?.firstname &&
                    wocman?.lastname &&
                    `${wocman?.lastname} ${wocman?.firstname}`
                  }
                  image={wocman?.image}
                />
              ))}
            </Flex>
            <Pagination
              page={page}
              shldPaginate={shldPaginate}
              prevPage={prevPage}
              nextPage={nextPage}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
