import instance from "./axios.config";

const getAllWocmanSearch = async (page, search) => {
  let data;
  if (search !== "") {
    data = await instance.get(
      `/admin/wocman?limit=6&page=${page}&search=${search}`
    );
  } else {
    data = await instance.get(`/admin/wocman?limit=6&page=${page}`);
  }

  return data;
};
const activateWocman = async ({ id, status }) => {
  const { data } = await instance.patch(
    `/admin/wocman/change-status/${id}?status=${status}`
  );

  return data;
};

export { getAllWocmanSearch, activateWocman };
