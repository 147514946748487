import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import React from "react";

const Header = ({ isMenuOpen, setIsMenuOpen }) => {
  const handleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Flex w="100%" justify="flex-end">
      <Box
        bgColor="#fff"
        p="0 62.25px 0 32px"
        boxShadow="md"
        pos="fixed"
        top="0"
        w={{ base: "100%", lg: "calc(100vw - 246px)" }}
        zIndex="200"
        className="header"
      >
        <Flex
          justify={{ base: "space-between", lg: "flex-end" }}
          align={{ base: "", lg: "center" }}
          h="106px"
        >
          <Flex justify={{ base: "space-between", lg: "" }} align="center">
            <Avatar
              bgColor="rgba(62, 126, 238, 0.2)"
              color="#3E7EEE"
              name="Alfred Bryan"
              w="74px"
              h="69px"
              mr={{ base: "18.25px" }}
            />
            <Text
              lineHeight="18px"
              textTransform="capitalize"
              ml="9px"
              mr="18.25px"
              fontWeight="bold"
              fontSize="15px"
              display={{ base: "none", md: "block" }}
            >
              Hello Admin
            </Text>

            <Box
              data-testid="hamburger-close"
              cursor="pointer"
              onClick={handleMenu}
              ml={{ base: "17rem", lg: "9px" }}
              display={{ md: "none" }}
            >
              {isMenuOpen ? (
                <CloseIcon h={"2.5rem"} w={"2.5rem"} />
              ) : (
                <HamburgerIcon h={"4rem"} w={"4rem"} />
              )}
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export { Header };
