import React, { useState } from "react";
import { Button, Flex, Input, Text, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";

import instance from "../../queries/axios.config";
import FormError from "./FormError";
import AuthBgImage from "../../assets/images/bgImage.png";

const EMAIL = "email";
const PASSWORD = "password";
const Login = () => {
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      [EMAIL]: "",
      [PASSWORD]: "",
    },
    validationSchema: Yup.object({
      [EMAIL]: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      [PASSWORD]: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      const body = {
        email: values.email,
        password: values.password,
      };
      //Login here
      adminLogin(body);
    },
  });
  const adminLogin = async (body) => {
    setIsLoading(true);
    try {
      const res = await instance.post("/auth/admin/signin", body);
      toast({
        title: "Success",
        description: "Login successful",
        position: "top-right",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      if (res.status === 200) {
        localStorage.setItem("admin_token", res?.data?.data?.accessToken);
        setTimeout(() => {
          window.location.replace("/admin");
        }, 1000);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "Failed",
        description: error?.response?.data?.message,
        position: "top-right",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      minH={"100vh"}
      bgImage={`url(${AuthBgImage})`}
      backgroundPosition="center"
      backgroundSize="contain"
      backgroundRepeat="repeat"
      py={4}
      flexDirection="column"
      backgroundColor="rgba(0, 0, 0, 0.05)"
    >
      <Flex
        flex={1}
        flexDirection={["column", "column", "column", "column", "row"]}
        justify="center"
        align="center"
      >
        <Flex
          justify="center"
          flex={[1, 1, 0.7, 0.8, 1]}
          flexDirection="column"
          px="32"
          display={["none", "none", "none", "none", "flex"]}
        >
          <Text
            as="h5"
            color="white"
            fontSize="3.3rem"
            fontFamily="Gilroy-Bold"
            lineHeight="68px"
            letterSpacing="-1px"
          >
            Welcome Wocman Admin
          </Text>
          <Text
            color="white"
            fontFamily="OverPass"
            mt={8}
            lineHeight="31px"
            width="55%"
          >
            Wocman Connects Customers to the nearest Wocman, with zero hassle.
          </Text>
        </Flex>
        <Flex
          flex="1"
          align="center"
          width={["96%", "90%", "90%", "80%", "50%"]}
          justify="center"
        >
          <Flex
            flexDirection="column"
            backgroundColor="white"
            height={["95%", "95%", "95%", "83%", "83%"]}
            width={["100%", "100%", "70%", "80%", "80%"]}
            borderRadius="10px"
            align="center"
            py={12}
          >
            <Text
              fontFamily="OverPass"
              color="wocman.contact"
              mb={6}
              fontSize="2.5rem"
              lineHeight="34px"
              letterSpacing="-0.55px"
            >
              Sign In
            </Text>
            <Flex textAlign="start" w="100%" px={[4, 4, 8, 12, 12]} mb={4}>
              <Text
                as="small"
                fontFamily="Gilroy-Medium"
                fontSize="16px"
                lineHeight="28px"
              >
                Email Address
              </Text>
            </Flex>
            <Flex w="100%" px={[4, 4, 8, 12, 12]} mb={6}>
              <Input
                placeholder="Email"
                minHeight={["1.5rem", "1.5rem", "1.5rem", "2.5rem", "3rem"]}
                px={6}
                width="100%"
                fontFamily="Gilroy-Medium"
                fontSize="14px"
                _focus={{ bg: "white" }}
                borderRadius="6px"
                opacity="0.5"
                borderColor="#1C1C1C"
                borderStyle="solid"
                id={EMAIL}
                name={EMAIL}
                type={EMAIL}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[EMAIL]}
              />
            </Flex>
            <Flex w="100%" px={[4, 4, 8, 12, 12]} mb={6}>
              <FormError formik={formik} inputName={EMAIL} />
            </Flex>
            <Flex textAlign="start" w="100%" px={[4, 4, 8, 12, 12]} mb={4}>
              <Text
                as="small"
                fontFamily="Gilroy-Medium"
                fontSize="16px"
                lineHeight="28px"
              >
                Password
              </Text>
            </Flex>
            <Flex w="100%" px={[4, 4, 8, 12, 12]}>
              <Input
                placeholder="Password"
                minHeight={["1.5rem", "1.5rem", "1.5rem", "2.5rem", "3rem"]}
                px={6}
                width="100%"
                fontFamily="Gilroy-Medium"
                fontSize="14px"
                _focus={{ bg: "white" }}
                borderRadius="6px"
                opacity="0.5"
                borderColor="#1C1C1C"
                borderStyle="solid"
                id={PASSWORD}
                name={PASSWORD}
                type={PASSWORD}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[PASSWORD]}
              />
            </Flex>
            <Flex w="100%" px={[4, 4, 8, 12, 12]} mb={6}>
              <FormError formik={formik} inputName={PASSWORD} />
            </Flex>
            <Flex w="100%" px={[4, 4, 8, 12, 12]}>
              <Button
                borderRadius="6px"
                color={"#fff"}
                mt="2rem"
                p="2rem"
                bg="brand.brown"
                w="100%"
                _hover={{ opacity: "0.8" }}
                isLoading={isLoading}
                onClick={formik.handleSubmit}
                fontSize={"16px"}
              >
                Sign In
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export { Login };
