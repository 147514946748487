/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import React, { useState, useEffect } from "react";
import moment from "moment";

import {
  getSingleProject,
  setProjectAmount,
  approveProject,
} from "../../queries";
import { BaseInput, Spinner } from "../../components";
import { useParams } from "react-router-dom";
import { statusCheck } from "../../utils";
import instance from "../../queries/axios.config";

export const SingleProject = () => {
  const [amount, setAmount] = useState("");
  const [project, setProject] = useState({});
  const [loading, setIsLoading] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [message, setMessage] = useState("");
  const [chats, setChats] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const { id } = useParams();
  const toast = useToast();

  const { isLoading, isError, data, refetch } = useQuery(
    ["getSingleProject", id],
    () => getSingleProject(id)
  );

  const fetchChats = async () => {
    if (project?.customerid) {
      try {
        const res = await instance.get(
          `/chat/${project?.projectid}/log/${project?.customerid}`
        );
        setChats(res?.data?.data?.chat);
      } catch (error) {
        toast({
          title: "Failed",
          description: error?.response?.data?.message,
          position: "top-right",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const sendChat = async () => {
    setIsSending(true);
    try {
      await instance.post(`/chat/send`, {
        receiverId: project?.customerid,
        projectId: project?.projectid,
        messageType: "text",
        message: message,
      });
      fetchChats();
      toast({
        title: "Success",
        description: "Sent",
        position: "top-right",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setMessage("");
      setIsSending(false);
    } catch (error) {
      setIsSending(false);
      toast({
        title: "Failed",
        description: error?.response?.data?.message,
        position: "top-right",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchChats();
  }, [project?.projectid]);

  useEffect(() => {
    setProject(data?.data);
  }, [data]);

  const amountData = { amount };

  const triggerPayment = async () => {
    setIsLoading(true);
    try {
      const res = await setProjectAmount(id, amountData);
      if (res.status) {
        toast({
          title: "Success",
          description: "Project amount added successfully",
          position: "top-right",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
        refetch();
      } else {
        toast({
          title: "Failed",
          description: "Sorry an error occurred",
          position: "top-right",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: error?.response?.data?.message,
        position: "top-right",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const status = "approved";

  const approveJob = async () => {
    setLoadingApprove(true);
    try {
      const res = await approveProject(id, status);
      if (res.status) {
        toast({
          title: "Success",
          description: "Project approved Successfully",
          position: "top-right",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setLoadingApprove(false);
      } else {
        toast({
          title: "Failed",
          description: "Sorry an error occurred",
          position: "top-right",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoadingApprove(false);
      }
    } catch (error) {
      toast({
        title: "Failed",
        description: error?.response?.data?.message,
        position: "top-right",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setLoadingApprove(false);
    }
  };

  if (isLoading || isError) {
    return (
      <Flex align="center" justify={"center"} w="100%" h="80vh">
        <Spinner />
      </Flex>
    );
  }

  return (
    <>
      {project?.quoteamount ? (
        <Flex
          justify={"space-between"}
          direction={{ base: "column", lg: "row" }}
          w="100%"
        >
          <Box w={{ base: "100%", lg: "60%" }}>
            <Flex
              h="33px"
              align={"center"}
              justify="center"
              color={"#fff"}
              bg="brand.brown"
              fontSize={"16px"}
            >
              Payment Summary
            </Flex>
            <Flex bg={"#fff"} w="100%" justify={"center"}>
              <Box w={{ base: "80%", lg: "60%" }}>
                {project?.status === "pending" && (
                  <Heading fontSize={"20px"}>
                    Waiting for payment confirmation
                  </Heading>
                )}
                <Text mt="46px" fontFamily={"bold"} fontWeight={"900"}>
                  Client Name:
                  <Text
                    fontWeight={"500"}
                    fontFamily={"regular"}
                    ml={".5rem"}
                    as={"span"}
                  >
                    {`${project?.customer?.firstname} ${project?.customer?.lastname}`}
                  </Text>
                </Text>
                <Text mt="15px" fontFamily={"bold"} fontWeight={"900"}>
                  Job title:
                  <Text
                    fontWeight={"500"}
                    fontFamily={"regular"}
                    ml={".5rem"}
                    as={"span"}
                    textTransform="capitalize"
                  >
                    {project?.project}
                  </Text>
                </Text>
                <Flex direction={{ base: "column", lg: "row" }} gap="10px">
                  <Text mt="15px" fontFamily={"bold"} fontWeight={"900"}>
                    Project status:
                    <Text
                      fontWeight={"500"}
                      fontFamily={"regular"}
                      ml={".5rem"}
                      as={"span"}
                      textTransform="capitalize"
                      color={statusCheck(project?.status)}
                    >
                      {project?.status}
                    </Text>
                  </Text>
                  <Text mt="15px" fontFamily={"bold"} fontWeight={"900"}>
                    Approval status:
                    <Text
                      fontWeight={"500"}
                      fontFamily={"regular"}
                      ml={".5rem"}
                      as={"span"}
                      textTransform="capitalize"
                      color={statusCheck(project?.status)}
                    >
                      {project?.status}
                    </Text>
                  </Text>
                </Flex>
                <Text mt="18px" fontSize={"16px"} fontWeight="300">
                  Description
                </Text>
                <Text mt="1rem" fontSize={"10px"} fontWeight="300">
                  {project?.description}
                </Text>
                <Flex
                  direction={{ base: "column", lg: "row" }}
                  align={"center"}
                  gap="20px"
                  pb="5rem"
                  mt="3rem"
                >
                  <Button
                    bg="#fff"
                    color={"brand.brown"}
                    border="1px solid #552D1E"
                    borderRadius={"8px"}
                    w={{ base: "100%", lg: "198px" }}
                    h="28px"
                    _hover={{ opacity: "0.8" }}
                    onClick={() => alert("You can't perform this action")}
                  >
                    CANCEL
                  </Button>
                  <Button
                    bg="#552D1E"
                    w={{ base: "100%", lg: "198px" }}
                    h="28px"
                    color={"#fff"}
                    borderRadius={"8px"}
                    isLoading={loadingApprove}
                    _hover={{ opacity: "0.8" }}
                    onClick={approveJob}
                  >
                    Approve
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Flex>
      ) : (
        <Flex
          justify={"space-between"}
          direction={{ base: "column", lg: "row" }}
          w="100%"
        >
          <Box w={{ base: "100%", lg: "60%" }}>
            <Flex
              h="33px"
              align={"center"}
              justify="center"
              color={"#fff"}
              bg="brand.brown"
              fontSize={"16px"}
            >
              Payment processing
            </Flex>
            <Flex bg={"#fff"} w="100%" justify={"center"}>
              <Box w="60%">
                <Text mt="46px" fontFamily={"bold"} fontWeight={"900"}>
                  Client Name:
                  <Text
                    fontWeight={"500"}
                    fontFamily={"regular"}
                    ml={".5rem"}
                    as={"span"}
                  >
                    {`${project?.customer?.firstname} ${project?.customer?.lastname}`}
                  </Text>
                </Text>
                <Text mt="15px" fontFamily={"bold"} fontWeight={"900"}>
                  Job title:
                  <Text
                    fontWeight={"500"}
                    fontFamily={"regular"}
                    ml={".5rem"}
                    as={"span"}
                    textTransform="capitalize"
                  >
                    {project?.project}
                  </Text>
                </Text>
                <Text mt="18px" fontSize={"16px"} fontWeight="300">
                  Description
                </Text>
                <Text mt="1rem" fontSize={"10px"} fontWeight="300">
                  {project?.description}
                </Text>
                <Flex mt="3rem" align={"center"}>
                  <Text fontFamily={"bold"} fontWeight="900" fontSize={"12px"}>
                    Agreed Price
                  </Text>
                  <Input
                    placeholder="Enter price"
                    ml="2rem"
                    w="50%"
                    type={"number"}
                    value={amount}
                    border={"1px solid grey"}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Flex>
                <Heading mb="1rem" mt="3rem" fontSize={"12px"}>
                  Payment channel details
                </Heading>
                <Image
                  src="https://assets.paystack.com/assets/img/hero/paystack-opengraph.png"
                  alt="paystack icon"
                />
                <Flex
                  direction={{ base: "column", lg: "row" }}
                  align={"center"}
                  gap="20px"
                  pb="5rem"
                  mt="3rem"
                >
                  <Button
                    bg="#fff"
                    color={"brand.brown"}
                    border="1px solid #552D1E"
                    borderRadius={"8px"}
                    w={{ base: "100%", lg: "198px" }}
                    h="28px"
                    _hover={{ opacity: "0.8" }}
                    onClick={() => alert("You can't perform this action")}
                  >
                    CANCEL
                  </Button>
                  <Button
                    bg="#552D1E"
                    w={{ base: "100%", lg: "198px" }}
                    h="28px"
                    color={"#fff"}
                    borderRadius={"8px"}
                    isLoading={loading}
                    isDisabled={amount?.length < 4}
                    _hover={{ opacity: "0.8" }}
                    onClick={triggerPayment}
                  >
                    TRIGGER PAYMENT
                  </Button>
                </Flex>
              </Box>
            </Flex>
          </Box>
          <Box
            pos={{ base: "static", lg: "relative" }}
            mt={{ base: "2rem", lg: "0rem" }}
            bg="#fff"
            w={{ base: "100%", lg: "35%" }}
          >
            <Flex
              align={"center"}
              justify="center"
              w="100%"
              h="70px"
              bg={"brand.brown"}
            >
              <Heading fontSize={"20px"} color={"#fff"}>
                Chat
              </Heading>
            </Flex>
            <Box padding={"5rem"}>
              {chats?.map((chat, id) => (
                <Box>
                  <Flex justify={"flex-end"}>
                    <Box>
                      <Box
                        mb="1rem"
                        bg="#C4C4C4"
                        padding={"1rem"}
                        borderRadius="10px"
                      >
                        {chat?.senderid === chat?.sender?.id && chat?.message}
                      </Box>
                      <Text color={"#C4C4C4"} fontSize={"10px"} ml="4rem">
                        {moment(chat?.chattime).format("LT")}
                      </Text>
                    </Box>
                  </Flex>
                  <Flex justify={"flex-start"}>
                    {chat?.senderid === project?.customerid && (
                      <Box>
                        <Box
                          mb="1rem"
                          bg="brand.brown"
                          padding={"1rem"}
                          borderRadius="10px"
                          color={"#fff"}
                        >
                          {chat?.senderid === project?.customerid &&
                            chat?.message}
                        </Box>
                        <Text color={"#C4C4C4"} fontSize={"10px"} ml="4rem">
                          {moment(chat?.chattime).format("LT")}
                        </Text>
                      </Box>
                    )}
                  </Flex>
                </Box>
              ))}
            </Box>
            <Flex
              left="32px"
              bottom="28px"
              pos={{ base: "static", lg: "absolute" }}
              ml={{ base: "1.5rem", lg: "0rem" }}
              mb={{ base: "1.5rem", lg: "0rem" }}
            >
              <BaseInput
                value={message}
                w={{ base: "20rem", lg: "25rem", xl: "40rem" }}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Button
                ml={{ base: "1rem", lg: "0rem" }}
                isLoading={isSending}
                onClick={sendChat}
                isDisabled={message === ""}
                _hover={{ opacity: "0.8" }}
                bg="brand.brown"
                color={"#fff"}
                h={"4.2rem"}
              >
                Send
              </Button>
            </Flex>
          </Box>
        </Flex>
      )}
    </>
  );
};
