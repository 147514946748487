import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import moment from "moment";

import briefcase from "../../assets/icons/briefcase.svg";
import calendar from "../../assets/icons/calendar.svg";
import clock from "../../assets/icons/clock.svg";
import whitecheck from "../../assets/icons/check-white.svg";
import wocstation from "../../assets/images/wocstation.svg";
import { getProjectView } from "../../queries";
import { useParams } from "react-router-dom";
import { Spinner } from "../../components";

export const WorkDescription = () => {
  const [project, setProject] = useState("");
  const { id } = useParams();

  const { isLoading, isError, data } = useQuery(["getProjectView", id], () =>
    getProjectView(id)
  );

  useEffect(() => {
    setProject(data?.data);
  }, [data]);

  if (isLoading || isError) {
    return (
      <Flex align={"center"} justify="center" w="100%" h="80vh">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box w="100%">
      <Flex w="100%" flexDir="column" background="white" p={{ base: 4, md: 8 }}>
        <Flex flexDir={{ base: "column", xl: "row" }}>
          <Flex flexDirection="column" flex={1} order={{ base: 1, xl: 0 }}>
            {" "}
            <Text
              as="small"
              fontFamily="Poppins"
              fontSize={{ base: "0.8rem", md: "1rem" }}
              color="wocman.typography2"
            >
              Work Description
            </Text>
            <Text
              fontFamily="Poppins"
              fontSize={{ base: "1.4rem", md: "1.6rem" }}
              mt={{ base: 4, md: 6 }}
              fontWeight="600"
              textTransform={"capitalize"}
            >
              {project?.project}
            </Text>
            <Text
              fontFamily="Poppins"
              fontSize={{ base: "0.8rem", md: "1.2rem" }}
              mt={{ base: 4, md: 6 }}
              fontWeight="200"
              lineHeight="24px"
            >
              {project?.description}
            </Text>
            <Flex my={{ base: 4, md: 6 }} align="center">
              <Image src={briefcase} alt="briefcase" size="1.2rem" />
              <Text
                color="wocman.typography2"
                fontFamily="Poppins"
                ml={[4]}
                fontSize="1.1rem"
              >
                {`${project?.address} ${project?.city} ${project?.country}`}
              </Text>
            </Flex>
            <Flex w="100%" flexDir={{ base: "column", sm: "row" }}>
              <Flex
                backgroundColor="wocman.dashboard"
                flex="3"
                flexDir="column"
                borderRadius="10px"
                mr={{ base: 0, sm: 2 }}
                px={[4]}
                py={4}
                mb={{ base: 4, sm: 0 }}
              >
                <Text
                  as="small"
                  fontFamily="Poppins"
                  color="wocman.typography2"
                  mb={[2, 4]}
                >
                  Appointment set for:
                </Text>
                <Flex>
                  <Flex flex="1">
                    <Flex flex="1" align="flex-start">
                      <Image src={calendar} alt="calendar" mr={[4, 8]} />
                      <Box>
                        <Text
                          fontFamily="Poppins"
                          color="wocman.typography2"
                          fontWeight="800"
                          lineHeight="27px"
                        >
                          {moment(project?.createdAt).format("LL")}
                        </Text>
                        <Text
                          as="small"
                          fontFamily="Poppins"
                          color="wocman.typography2"
                          fontWeight="200"
                        >
                          Date
                        </Text>
                      </Box>
                    </Flex>
                    <Flex flex="1" align="flex-start">
                      <Image src={clock} alt="clock" mr={[4, 8]} />
                      <Box>
                        <Text
                          fontFamily="Poppins"
                          color="wocman.typography2"
                          fontWeight="800"
                          lineHeight="27px"
                        >
                          10:45AM
                        </Text>
                        <Text
                          as="small"
                          fontFamily="Poppins"
                          color="wocman.typography2"
                          fontWeight="200"
                        >
                          Time
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>
                  {/* <Flex flex="1"></Flex> */}
                </Flex>
              </Flex>
              <Box
                as="button"
                borderRadius="10px"
                backgroundColor="wocman.typography1"
                mb={{ base: 4, sm: 0 }}
                _hover={{ opacity: "0.7" }}
                _active={{ transform: "scale(0.98)" }}
                _focus={{ outline: "none" }}
              >
                <Flex
                  justify="center"
                  align="center"
                  flexDir="column"
                  px={[4, 8]}
                  py={[2]}
                >
                  <Image src={whitecheck} alt="check" />
                  <Text fontFamily="Poppins" color="white">
                    Begin
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </Flex>
          <Flex flex={1} justify="center">
            <Image src={wocstation} alt="wocstation" />
          </Flex>
        </Flex>
        <Flex flexDir="column">
          <Flex align="center" my={{ base: 4, md: 6 }}>
            <Text fontFamily="Poppins" color="wocman.typography1">
              See Work Images
            </Text>
            <Icon
              name="arrow-forward"
              color="wocman.typography1"
              size="1.5rem"
              ml={[4, 8]}
            />
          </Flex>
          <Flex w="100%"></Flex>
        </Flex>
        <Box mb={[8, 4]}>
          {project?.images?.length < 1 ? (
            "This project has no images"
          ) : (
            <Flex
              direction={{ base: "column", lg: "row" }}
              justify={"space-between"}
              w="100%"
            >
              {project?.images?.map((img, idx) => (
                <Image
                  key={idx}
                  height={"300px"}
                  w={{ base: "100%", lg: "30%" }}
                  src={img}
                />
              ))}
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  );
};
