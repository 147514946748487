import React, { createContext, useContext, useState } from "react";


const MenuContext = createContext(window.screen.width >= 768 ? true : false);

const MenuProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(
    window.screen.width >= 768 ? true : false
  );

  return (
    <MenuContext.Provider value={{ isMenuOpen, setIsMenuOpen }}>
      {children}
    </MenuContext.Provider>
  );
};

export default MenuProvider;

export const useMenu = () => useContext(MenuContext);
