import { Box } from "@chakra-ui/react";
import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import {
  Dashboard,
  RecentProjects,
  SingleProject,
  Projects,
  ManageWocman,
  SingleWocman,
  ViewProject,
} from "../pages";
import { Sidebar, Header } from "../components";
import { useMenu } from "../context/MenuContext";
import { PrivateRoute } from "./PrivateRoute";

function AdminRoutes() {
  const { isMenuOpen, setIsMenuOpen } = useMenu();
  return (
    <Router>
      <Box
        h="calc(100vh - 10px)"
        background="#F7F9FB"
        pos="relative"
        overflow="scroll"
      >
        <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        <Sidebar isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        <Box
          m={{ base: "110px 0 0", lg: "100px 0 0 246px" }}
          p="30px 57px 30px 38px"
        >
          <Switch>
            <PrivateRoute
              path="/admin/recent-projects"
              component={RecentProjects}
            />
            <PrivateRoute
              path="/admin/recent-project/:id"
              component={SingleProject}
            />
            <PrivateRoute path="/admin/projects" component={Projects} />
            <PrivateRoute exact path="/admin" component={Dashboard} />

            <PrivateRoute
              exact
              path="/admin/manage-wocmen"
              component={ManageWocman}
            />
            <PrivateRoute
              exact
              path="/admin/manage-wocman/:id"
              component={SingleWocman}
            />
            <PrivateRoute
              exact
              path="/admin/project/:id"
              component={ViewProject}
            />
          </Switch>
        </Box>
      </Box>
    </Router>
  );
}

export { AdminRoutes };
